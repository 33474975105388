.mantine-1n3zr08 {
  justify-content: center !important;
}
.mantine-d3oese {
  flex-direction: column !important;
}

.mantine-1uskmy4 {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
}


.css-21lvu3{
  padding-left:0px;
  padding-right: 0px;
}