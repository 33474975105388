.css-11m2ynp-MuiList-root-MuiMenu-list {
  background-color: black;
}

.css-1e7vw6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  width: 170px;
}

.css-597a0j-MuiButtonBase-root-MuiButton-root {
  margin-top: 30px;
}

.MuiFormControl-root .css-1nrlq1o-MuiFormControl-root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.button-7 {
  background-color: #0095ff;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding: 8px .8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.button-7:hover,
.button-7:focus {
  background-color: #07c;
}

.button-7:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.button-7:active {
  background-color: #0064bd;
  box-shadow: none;
}
