

span.css-14lo706  {
opacity: 1 !important;
color: rgba(255, 255, 255, 0.6) !important;
}

.css-14lo706 span.MuiOutlinedInput-notchedOutline {
    opacity: 1!important;
    background: rgba(60, 55, 255, 0.03);
    border: 1.5px solid #3C37FF;
    border-radius: 10px;
height: 20px;
}
 .disabledValue fieldset.MuiOutlinedInput-notchedOutline.css-9425fu-MuiOutlinedInput-notchedOutline  {
    background: rgba(60, 55, 255, 0.03);
border: 1.5px solid rgba(255, 255, 255, 0.6);
border-radius: 10px;
}

.ActiveValue fieldset.MuiOutlinedInput-notchedOutline.css-9425fu-MuiOutlinedInput-notchedOutline  {
    background: rgba(60, 55, 255, 0.03);
    border: 1.5px solid #3C37FF;
    border-radius: 10px;
}

.disabledValue fieldset legend.css-14lo706>span{
    color: rgba(255, 255, 255, 0.6);
}

.css-1e7vw6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    opacity: 1;
    -webkit-text-fill-color: #FFFF !important;
}

.activePayment{
    border: 1px solid rgba(60, 55, 255, 0.5);
box-shadow: 0px 4px 50px 50px rgba(44, 42, 42, 0.15);
}



fieldset legend.css-14lo706>span{
    height: 20px;
    font-size: 12px;
    opacity: 1 !important;
}
fieldset legend.css-14lo706{
    height: 20px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    opacity: 1 !important;
}
.Mui-disabled fieldset legend.css-14lo706{
    height: 20px;
    color: yellow;
    border:1px solid red;
    font-size: 12px;
    opacity: 1 !important;
}

.MuiSelect-outlined{
    color: rgba(255, 255, 255, 0.6);
}