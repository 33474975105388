input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  text-align: center;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.paginationBox{
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.languageChangeBtn{
  margin: 18px 8px 8px 20px;
  padding: 8px 8px;
}
.sideTray{
  width: 100%;
  max-width: 250px;
  min-width: 180px;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  overflow: hidden;
}
.sideTarayHeader{
  display:flex;
  justify-content:center;
  padding-block:14px;
  border-bottom:1px solid #333;
  background:#0D0D36; 
  height: 80px;
}
.sideTarayHeader .logoImage{
  height: auto;
}
.mobileMenu{
  height: calc(100% - 155px);
}
.mobileMenuList{
  list-style: none;
  padding: 0px;
  margin: 0 auto; 
  height: 100%;
  overflow-y: auto;
}
.mobileMenuList li + li{
  border-top:1px solid #333;
}
.mobileMenuList li a{
  width: 100%;
  display: block;
  margin: 0;
  text-align: start;
  padding: 10px;
  color: #000;
  box-sizing: border-box;
}
.sideTrayFooter{
  height: 43px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#chatling-embed-script{
  display: none !important;
}
.loaderMain{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .modalForScreenIndication{
    display: block; 
    max-width: 100vh;
    width: 100vh;
    height: 100vw;
 
  }
  .modalForScreenIndication .MuiDialog-container {
    align-items: center;
    height: 100vw;
    width: 100vh;
  }
  .modalForScreenIndication .MuiDialog-container .MuiPaper-elevation{
    margin: 0px; 
    transform: rotate(-90deg) translate(0%);
  }
 
  .loaderMain{
    height: 55vh !important;
  }
}
 

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 
#root ~ div:has(#chatling-open-chat-icon) {
  bottom: 90px !important;
  right: 40px !important;
}

 

@media screen and (max-width:1100px) {
  #root ~ div:has(#chatling-open-chat-icon) {
    bottom: 30px !important;
    right: 20px !important;
  }
  #chatling-chat-iframe{
    bottom: 100px !important;
    min-height: 240px !important;
  }  
}
@media screen and (max-width:992px) {
  .nav-landscape{
    display: none !important;
  }
  .languageChangeBtn{
    margin: 10px;
  }
}
@media screen and (max-width:767px) {
  #root ~ div:has(#chatling-open-chat-icon) {
    bottom: 30px !important;
    right: 20px !important;
  }
  #chatling-chat-iframe{
    bottom: 80px !important;
    min-height: 200px !important;
  }  
}

.MuiModal-backdrop, .swal2-container{
  background-color: rgb(143 143 143 / 50%) !important;
}