.table {
    color: white;
    border-collapse: collapse;
    border: 1px solid;
    padding: 10px;
    width: 100%;
  overflow: auto;
    display: block;
    transition-duration: 0.3s;
    /* height: 492px; */
    background: rgb(5, 5, 24);
    box-shadow: rgba(181, 181, 181, 0.05) 0px 4px 50px 30px;
    border-radius: 5px;
  }
  .td {
    text-align: center;
      padding: 5px 10px;
      border-collapse: collapse;
      border: 1px solid white;
  }
  .table td {
    /* border: 1px solid white; */
    /* width:50% */
  }
  
  .th td {
    padding: 10px;
  }
  
  .search {
    background: transparent;
    border-radius: 7px;
    padding: 10px;
    margin: 15px 0px;
    border: 1px solid white;
    color: white;
  }
  .minw-maxcontent {
    min-width: max-content !important;
  }
  .MyTransactionList {
    overflow: auto;
    margin-bottom: 20px;
  }
  .MyTransactionList thead td {
    border-bottom: 1px solid white;
    padding-bottom: 10px;
  }
  .MyTransactionList thead div {
    margin: auto 140px;
    
  }
  /* .MyTransactionList tbody td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    min-width: 100px;
  } */
  
  