.parent.parent-div {
    position: relative ;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
  }

  .eLmXxz {
    transform: rotate(108.203deg);
}
  .parent.parent-div  img{
    content:url("../../../assets/spinnerIcon.svg") !important;
    
    height:42px;
    width:23px;
    position: relative;
    left: 149px;
    top: -30px;
  }
  .circle1.circle-first {
    
    position: absolute;
  }
    .circle1.circle-first  div{
  
    visibility: visible !important;
    
}
.circle0.circle-zero {
  width: 540px;
  height: 540px;
  background-position: 50% 87%;
   background-image: url("../../../assets/spinnerFrame.png");
   background-repeat: no-repeat;
}
.outerImg {
  height: 100%;
  width: 100%;
  max-height: unset;
  max-width: unset;
  visibility: visible !important;
  position: relative;
}
  .circle1.circle-first  div{
  
  visibility: visible !important;
}

.circle2.circle-second {
    
    position: absolute;
  }
    .circle2.circle-second  div {
      
    visibility: visible !important;
}

.circle2.circle-second div img{
  visibility: hidden;
}

.circle3.circle-third {
    
    position: absolute;
  }
    .circle3.circle-third  div {
        
    visibility: visible !important;
}

.circle4.circle-four {
  
  position: absolute;
}
  .circle4.circle-four  div {
      
  visibility: visible !important;
}

.circle5.circle-five {
 
  position: absolute;
}
  .circle5.circle-five  div {
      
  visibility: visible !important;
}
.circle6.circle-six {
 
  position: absolute;
}
  .circle6.circle-six  div {
      
  visibility: visible !important;
}


 

.circle7.circle-seven{
  width: 219px !important;
    height: 241px !important;
    position: absolute;
    z-index: 99999;
    /* padding: 102px 50px 0px 10px; */
    top: 114px;
    display: flex;
    justify-content: center;
    transform: 50%;
    margin: auto;
}

.rpm {
  position: relative;
    top: 65%;
    left: 11%;
}

.rpm1{
    position: absolute;
    top: calc(100% - 140px);
    left: 65px;
}
.rpm2 {
    position: absolute;
}
 .rpm3 {
  position: absolute;
  color: white;
  top: 5px;
  left: 20px;
  text-align: center;
 }

 .speed3 {
  position: absolute;
  color: white;
  top: 5px;
  left: 30px;
  text-align: center;
 }

 .speed {
  position: absolute;
  right: 160px;
  top: calc(100% - 140px);
}

.circle7.circle-seven  div {
  height: 48%;
  width: 0%;
  justify-content: flex-end;
  position: sticky !important;
  max-height: 232px;
  max-width: 230px;
  background-color: transparent;
  color: "black";
  display: flex;
  
  justify-content: center;
  align-items: center;
  animation: glow 1.5s ease-in-out infinite;
  border: 1px solid yellow;
  position: fixed;
}

.circle8.circle-eight{
  width: 70px !important;
    height: 70px !important;
    position: absolute;
    z-index: 999;
}

.circle8.circle-eight  div {
  height: 100%;
    width: 100%;
max-height: unset;
max-width: unset;
background: radial-gradient(49.40% 49.40% at 50.00% 48.23%, #07A0D3 0%, #077AA3 49.23%, #046085 100%);
color:"black";
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
}

@keyframes glow {
  0% {
    box-shadow:
      inset 0 0 10px #ffd700,      /* inner golden */
      inset 20px 0 20px #ffd700,   /* inner left golden short */
      inset -20px 0 20px #ffd700,  /* inner right golden short */
      inset 20px 0 100px #ffd700,  /* inner left golden broad */
      inset -20px 0 100px #ffd700, /* inner right golden broad */
      0 0 20px #ffd700,            /* outer golden */
      -10px 0 40px #ffd700,        /* outer left golden */
      10px 0 40px #ffd700;         /* outer right golden */
  }
  50% {
    box-shadow:
      inset 0 0 40px #fff,      /* inner white */
      inset 20px 0 80px #f0f,   /* inner left magenta short */
      inset -20px 0 150px #0ff,  /* inner right cyan short */
      inset 20px 0 80px #ffd700,  /* inner left magenta broad */
      inset -10px 0 30px #0ff, /* inner right cyan broad */
      0 0 80px #fff,            /* outer white */
      -10px 0 180px #f0f,        /* outer left magenta */
      10px 0 80px #0ff;         /* outer right cyan */
  }
  100% {
    box-shadow:
      inset 0 0 10px #ffd700,      /* inner golden */
      inset 20px 0 20px #ffd700,   /* inner left golden short */
      inset -20px 0 20px #ffd700,  /* inner right golden short */
      inset 20px 0 100px #ffd700,  /* inner left golden broad */
      inset -20px 0 100px #ffd700, /* inner right golden broad */
      0 0 20px #ffd700,            /* outer golden */
      -10px 0 40px #ffd700,        /* outer left golden */
      10px 0 40px #ffd700;         /* outer right golden */
  }
}


.circle1.circle-first div img{
  content:url("../../../assets/spinnerIcon.svg") !important;
  height: 34px;
    width: 23px;
    /* bottom: 30px; */
    position: relative;
    top: -13px;
}

.circle3.circle-third div img{
  visibility: hidden;
}

.circle4.circle-four div img{
  visibility: hidden;
}
.circle5.circle-five div img{
  visibility: hidden;
}
.circle6.circle-six div img{
  visibility: hidden;
}

.circle3.circle-third .spin-button {
position: absolute;
z-index: 99999999999;
top: 54%;
left: 39%;
font-size:14px;
background: transparent;
    border: none;
    font-weight: 400;
}


.circle1.circle-first  .spin-button {
  display: none;
  pointer-events: none;
  }

  .item {
    /* display: flex !important; */
    justify-content: space-between;
  }
  .item label{
  height: 11px;
  left: 20px;
  top: 14px;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  color: rgba(27, 28, 45, 0.6);
  }
  .item .detail1{
  display: flex !important;
  justify-content: space-between;
  padding: 14px 20px 8px 20px;
  }
  .item .detail2{
  display: flex !important;
  justify-content: space-between;
  padding: 0  20px  14px 20px;
  }
  .item .detail .number{
  height: 23px;
  left: 20px;
  top: 28px;
  font-style: normal;
  font-weight: 700;
  font-size: 18.3636px;
  line-height: 23px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #27272F;
  }
  .item .detail .time{
  
  width: 82px;
  height: 16px;
  left: 171px;
  top: 32px;
  
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 16px;
  color: #1B1C2D;
  }
  h5{
  
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  color: #FFFFFF;
  }
  
  .div1{
  max-width: 297px;
  width: 100%;
  height: 155px;
  background: #FFFFFF;
  border-radius: 11.2668px;
  cursor: pointer;
  }
  .div2{
      color: white !important;
      width: 297px;
      height: 155px;
      float: right;
      border-radius: 11.2668px;
      background: #3C37FF;
      top: 0px;
      position: absolute;
      right: 0;
  
  }
  .div2 label ,.div2  p {
    color: white !important;
  }
  .jackpot{
  display: flex;
  justify-content: space-around;
  left: 0px;
  top: 59px;
  width: 297px;
  height: 38px;
  background: rgba(60, 55, 255, 0.15);
  }
  .div2 .jackpot{background: rgba(255, 255, 255, 0.15)!important}
  .jackpot p{
  width: 99px;
  height: 23px;
  font-style: normal;
  font-weight: 700;
  font-size: 20.3636px;
  line-height: 23px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #27272F;
  margin: auto;
  }
  .polyup{
  position: absolute;
  width: 30px !important;
  height: 30px;
  left: 176px;
  top: 3px;
  /* transform: rotate(180deg); */
  }
  .active{
    color: #3b97bb !important;
  }

  

  .wheel-arrow {
    position: relative;
    top:4px;
  }

#tsparticles {
  z-index: -1;
  position: absolute;
}


  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-weight: 900;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border: none;
    z-index: 2147483647;
    border: none;
    outline: none !important;
  }
  
  .modal-button {
    margin-top: 8px;
    background-color: blue;
    color: white !important;
    padding:22px 32px 22px 32px !important;
border-radius: 15px !important;
border: 1px;
background-color: #3C37FF !important;
backdrop-filter: blur(5px); /* Apply blur effect */
font-size: 22px !important;
font-weight: 900;
  }

  .inactive {
    color:#fff !important;
  }
  .polydown{
  position: absolute;
  width: 30px !important;
  height: 30px;
  left: 176px;
  bottom: -15px;
  transform: rotate(180deg);
  }
  .carousel-control{
    height: 26px;
    left: -45px;
  }
  .Carouselbtn{
    position: absolute;
    right: 30px;
  }
  .Carouselbtn .left span {
    
    background-repeat: no-repeat;
    transform: rotate(180deg);
    top: 6px;
  }
  .Carouselbtn .left span::before {
    content: none !important;
  }
  .Carouselbtn .right span {
   
    background-repeat: no-repeat;
  
  }
  .Carouselbtn .right span::before {
    content: none !important;
  }
/* 
85rpm --->
.started-spinning {
    animation: 
    1.41s cubic-bezier(0.71, -0.29, 0.96, 0.9) 0s 1 normal forwards running spin,
    1.41s linear 1.41s 1 normal forwards running continueSpin, 
  1.41s cubic-bezier(0, 0, 0.35, 1.02) 2.811s 1 normal forwards running stopSpin !important;
  }

  @keyframes spin {
    0% {
      transform: rotate(-52.1707deg);
  }
  100% {
      transform: rotate(360deg);
  }
  }
  @keyframes continueSpin {
    0% {
      transform: rotate(-52.1707deg);
  }
  100% {
      transform: rotate(360deg);
  }
  }
  @keyframes stopSpin {
    0% {
      transform: rotate(-52.1707deg);
  }
  100% {
    transform: rotate(2500deg);
  }
  } */

  :root {
    --rpm: 60; /* Replace 85 with your desired RPM value */
    /* Calculate the final rotation degrees for gradual stop */
    --finalRotationDegrees: calc(360deg + (1440deg / var(--rpm) / 2));
  }
  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes accelerate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes constantSpeed {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(3600deg);
    }
  }
  
  @keyframes decelerate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(7200deg);
    }
  } 

.box-spin {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 10px;
}
.box-spin.box-spin2{
  display: none;
}

.spiner-circle {
  font-weight: bold;
  text-align: center;
  display: block;
  color: #ffff;
  /* display: flex; */
  padding: 24px 17px 21px 8px;
  /* text-align: center; */
  /* align-items: center; */
  /* justify-content: center; */
  /* display: flex; */
  /* top: 35%; */
  font-size: 18px;
  /* position: absolute; */
  /* left: 10%; */
}



.font-spin {
  background: #3b97bb;
  padding: 13px;
  font-size: 24px;
  color: #fff;
  font-weight: 900;
  border-radius: 10px;
  min-width: 180px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .box-spin{
    top:-10px;
    left: 20px;
  }
  .font-spin span{
    padding: 0 10px !important;
  }
}
 
@media screen and (min-width: 0px) and (max-width:600px) and (orientation:landscape){
  .table-oriantation-fix tbody{
    max-height: 110px !important;
  }
  #lottery-tickets-list-wheellpage{
    max-height: 200px;
  } 
}
@media screen and (min-width: 600px) and (max-width:960px) and (orientation:landscape){
  .table-oriantation-fix tbody{
    max-height: 110px !important;
  }
  .table-oriantation-fix h6{
    height: 38px !important;
  }

}
@media screen and (min-width: 600px) and (max-width:800px) and (orientation:landscape){
  #lottery-tickets-list-wheellpage{
    max-height: 200px;
  }
}
@media screen and (min-width: 800px) and (max-width:960px) and (orientation:landscape){
  #lottery-tickets-list-wheellpage{
    max-height: 320px;
  }
}
@media screen and (min-width: 320px) and (max-width: 1201px) and (orientation:landscape){
    
  .css-5ctrnn{
      margin-top: 0px;
  }  

}
 
 