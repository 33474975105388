.css-1pscu7x-MuiButtonBase-root-MuiMenuItem-root {
  cursor: default !important;
}
.cstm1-crousel{
  height: 100%;
  min-height: 250px;
}
.cstm1-crousel > div, .cstm1-crousel > div >div>div>div>div{
  height: 100% !important;
  align-items: end;
}

.cstm1-crousel.loginSignUp div:nth-child(4) {
  bottom: 0px;
  position: relative;
  align-items: end;
}
@media (max-width: 930px) {
  .top-container-card-bg {
    background: rgba(5, 5, 24, 0.8);
    border-radius: 20px;
    padding: 22px;
    margin-top: 110px !important;
  }
}

@media (max-width: 720px) {
  .mantine-1bi7v2x {
    margin-top: 0rem;
  }
}

@media (max-width: 900px) {
  .try {
    width: auto !important;
  }

  .mervlogo img {
    height: 40px !important;
  }

  .nav-landscape a {
    font-size: 14px !important;
    margin-inline: 4px;
  }

  .img-landscape-mid-logo img {
    width: 181px;
    position: absolute;
    top: 50%;
    left: 54% !important;
  }

  .landscape-wallet-div {
    top: 0px !important;
  }
}

@media (max-width:768px){
  .nav-landscape a {
    font-size: 10px !important;
    margin-inline: 4px;
    white-space: nowrap;
  }
}


/* new css landscape headerfix end*/

@media (min-width: 1100px) and (max-width: 1500px) {
  
  .cstm-crousel h2 {
    font-size: 1.5rem !important;
  }
  .cstm1-crousel h2 {
    font-size: 1.5rem !important;
  }

  .cstm-crousel h6 {
    font-size: 1rem !important;
  }
  .cstm1-crousel h6 {
    font-size: 1rem !important;
  }

  .cstm-crousel div:nth-child(4) {
    bottom: 0px;
    position: absolute;
  }
  .cstm1-crousel div:nth-child(4) {
    bottom: 0px;
    position: absolute;
    align-items: end;
  }
  
}

@media (min-width: 1501px) and (max-width: 1724px) {
  .cstm-crousel {
    height: 62% !important;
  }
  .cstm1-crousel {
    height: 62% !important;
  }
  .cstm-crousel div:nth-child(4) {
    bottom: 0px;
    position: absolute;
  }
  .cstm1-crousel div:nth-child(4) {
    bottom: 0px;
    position: absolute;
  }
   
}

@media (min-width: 1725px) {
  .cstm-crousel {
    height: 190px !important;
  }

  .cstm-crousel h2 {
    font-size: 2rem !important;
  }
  .cstm1-crousel h2 {
    font-size: 2rem !important;
  }

  .cstm-crousel h6 {
    font-size: 1.2rem !important;
  }
  .cstm1-crousel h6 {
    font-size: 1.2rem !important;
  }
  .cstm-crousel div:nth-child(4) {
    bottom: 0px;
    position: absolute;
  }
  .cstm1-crousel div:nth-child(4) {
    bottom: 0px;
    position: absolute;
  }
 
}

/* .wheel-cstm{
    left: 51.5% !important;
    top: 38% !important;
} */

@media (min-width: 990px) and (max-width: 1100px) {
  .wheel-cstm {
    left: 46% !important;
    top: 36.8% !important;
  }
}

@media (min-width: 1100px) and (max-width: 1150px) {
  .wheel-cstm {
    left: 48.5% !important;
    top: 38.5% !important;
  }
}

@media (min-width: 1150px) and (max-width: 1200px) {
  .wheel-cstm {
    left: 50% !important;
    top: 39% !important;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .wheel-cstm {
    left: 50.8% !important;
    top: 39.5% !important;
  }
}

@media (min-width: 1300px) and (max-width: 1500px) {
  .wheel-cstm {
    left: 51.85% !important;
    top: 40% !important;
  }
}

@media (min-width: 1500px) {
  .wheel-cstm {
    left: 51.7% !important;
    top: 40% !important;
  }
}

ul .MuiMenu-list h6 {
  font-size: 16px !important;
}

.cstm-wallet {
  margin-top: 30px !important;
}

.cstm-wallet input {
  height: 26px !important;
}

.cstm-wallet button {
  height: 26px !important;
  padding: 29px 0px !important;
}

@media (max-width: 700px) {
  .top-container-card-bg {
    background: rgba(5, 5, 24, 0.8);
    border-radius: 20px;
    padding: 22px;
    margin-top: 110px !important;
  }

  .small-device-upComingLotteries {
    padding: 10px !important;
  }

  .small-device-upComingLotteries .inner-grid {
    max-width: 50% !important;
  }

  .small-device-upComingLotteries .inner-grid .img-card {
    background-position: center !important;
  }

  .small-device-replayLottery {
    padding: 10px !important;
  }

  .small-device-replayLottery .inner-grid {
    max-width: 50% !important;
  }

  .small-device-replayLottery .inner-grid button {
    font-size: x-small;
  }

  .small-device-replayLottery .inner-grid .img-card {
    background-position: center !important;
  }

  .Select-Payment-System .activePayment {
    display: block;
    text-align: center;
  }

  .Select-Payment-System .activePayment h3 {
    font-size: 11px;
  }

  .Select-Payment-System .activePayment img {
    margin: auto;
  }

  .Lotteries-buy-page {
    text-align: center;
  }

  .Lotteries-buy-page img {
    border-radius: 20px;
  }

  .small-device-w100 {
    width: 100%;
  }

  .small-device-m-auto {
    margin: auto !important;
  }
}

.swal2-popup {
  width: 17em !important;
}

.swal2-actions {
  border-radius: 10px !important;
  background: blue !important;
}

.swal2-styled.swal2-confirm {
  color: #fff !important;
}

.swal2-icon.swal2-error {
  border-color: #3c37ff !important;
  color: #3c37ff !important;
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  border-radius: 0.125em;
  background-color: #3c37ff !important;
}

.swal2-container.swal2-center > .swal2-popup {
  border-radius: 10px !important;
}

.swal2-styled {
  box-shadow: none !important;
}

.swal2-styled:focus {
  background-color: transparent !important;
}

.swal2-confirm {
  border-radius: 6px !important;
}

.swal2-cancel {
  border-radius: 6px !important;
  color: #fff !important;
}

.swal-actions-class {
  background-color: black !important;
  /* Custom background color for the actions */
}

#wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Set the container height to fill the viewport */
}

#warning-message {
  text-align: center;
  padding: 20px;
  border: 2px solid #333;
}

.input-otp {
  border: 1px Solid rgba(255, 255, 255, 0.31);
  border-radius: 9px;
  color: #ffff;
  background: transparent;
  height: 50px;
  width: 50px !important;
}

@media screen and (min-width: 320px) and (max-width: 780px) and (orientation: landscape) {
  #warning-message {
    display: none;
  }

  .cstm-wallet button {
    height: 26px !important;
    padding: 27px 42px !important;
  }

  .css-1rv3ei8-MuiFormControl-root-MuiTextField-root {
    margin-top: 8px !important;
    margin-bottom: 8px;
  }

  .css-17vbkzs-MuiFormControl-root-MuiTextField-root {
    margin-top: 8px !important;
  }

  .swal2-x-mark-line-left {
    left: 0.1em !important;
  }

  .swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="right"] {
    right: 0em !important;
  }

  /* .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
    top: 0.3125em !important;
    width: 0.9375em !important;
    height: 0.3125em !important;
  } */
  .swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="left"] {
    left: 1.0625em !important;
  }
  .swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="right"] {
    right: 1em !important;
  }
  .input-otp {
    height: 29px !important;
    width: 29px !important;
  }

  .image-otp {
    display: none !important;
  }

  div:where(.swal2-container) .swal2-html-container {
    font-size: 0.8em !important;
  }

  .swal2-title {
    font-size: 0.875em !important;
  }

  /* .swal2-icon {
    width: 1em !important;
    height: 1em !important;
  } */

  .swal2-icon .swal2-icon-content {
    font-size: 0.75em !important;
  }

  .css-14kills-MuiInputBase-root-MuiOutlinedInput-root {
    font-size: 12px !important;
  }

  .log-merv-wallet {
    height: 10px !important;
    width: 10px !important;
    top: 1px !important;
  }

  .Choose-homescreen img {
    width: 8px !important;
    height: 8px !important;
    margin: 2px !important;
    padding: 6px !important;
  }

  .Choose-homescreen button {
    font-size: 10.593px !important;
  }

  .image-big {
    height: 74px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
  /* #container {
    transform-origin: 340px;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    top: 100%;
    height: 100vw;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  } */

  #wrapper {
    display: none;
  }

  #warning-message {
    display: block;
  }

  .logoImage {
    height: 40px !important;
  }
}

@media screen and (min-width: 120px) and (max-width: 376px) and (orientation: portrait) {
  /* html {
    top: 4px !important;
    left: -256px !important;
    transform: rotate(-270deg) !important;
    transform-origin: 340px;
    height: 90vh !important;
    width: 111vh !important;
    position: fixed;
    overflow: hidden !important;
  }

  #root {
    height: 60vh;
    overflow-x: hidden;
    transform: scale(0.9) !important;
    margin-top: 0px;
    padding-left: 30px;
    padding: 0px;
  } */

  .Choose-homescreen button {
    font-size: 10.593px !important;
  }
}

@media screen and (min-width: 377px) and (max-width: 390px) and (orientation: portrait) {
  /* html {
    top: -43px !important;
    left: -264px !important;
    transform: rotate(-270deg) !important;
    transform-origin: 340px;
    height: 80vh !important;
    width: 111vh !important;
    position: fixed;
    overflow: hidden !important;
  }

  #root {
    height: 60vh;
    overflow-x: hidden;
    transform: scale(0.9) !important;
    margin-top: 0px;
    padding-left: 30px;
    padding: 0px;
  } */

  .Choose-homescreen button {
    font-size: 10.593px !important;
  }
}

@media screen and (min-width: 391px) and (max-width: 414px) and (orientation: portrait) {
  /* html {
    top: -65px !important;
    left: -260px !important;
    transform: rotate(-270deg) !important;
    transform-origin: 340px;
    height: 80vh !important;
    width: 111vh !important;
    position: fixed;
    overflow: hidden !important;
  }

  #root {
    height: 60vh;
    overflow-x: hidden;
    transform: scale(0.9) !important;
    margin-top: 0px;
    padding-left: 30px;
    padding: 0px;
  } */

  .Choose-homescreen button {
    font-size: 10.593px !important;
  }
}

@media screen and (min-width: 415px) and (max-width: 430px) and (orientation: portrait) {
  /* html {
    top: -82px !important;
    left: -258px !important;
    transform: rotate(-270deg) !important;
    transform-origin: 340px;
    height: 80vh !important;
    width: 111vh !important;
    position: fixed;
    overflow: hidden !important;
  }

  #root {
    height: 60vh;
    overflow-x: hidden;
    transform: scale(0.9) !important;
    margin-top: 0px;
    padding-left: 30px;
    padding: 0px;
  } */

  .Choose-homescreen button {
    font-size: 10.593px !important;
  }
}

.swal2-container {
  z-index: 999999999 !important;
}

@media screen and (min-width: 120px) and (max-width: 767px) and (orientation: portrait) {
  /* html {
    transform: rotate(-270deg);
    transform-origin: 340px;
    width: 100vh;
    height: 100vw;
    top: 70px;
    left: -85px;
    position: fixed;
    overflow: hidden !important;
  }
 #root {
    height: 60vh;
    overflow-x: hidden;
    transform: scale(0.96);
    margin-top: 0px;
    padding-left: 30px;
    padding: 0px;
  } */

  .Choose-homescreen button {
    font-size: 10.593px !important;
  }

  #basic-menu .MuiPaper-elevation {
    top: 82px;
    right: 129px;
  }

  .try {
    width: auto !important;
  }

  .nav-landscape a {
    font-size: 14px !important;
  }

  .img-landscape-mid-logo img {
    width: 181px;
    position: absolute;
    top: 50%;
    left: 54% !important;
  }

  .mervlogo img {
    height: 40px !important;
  }

  .landscape-wallet-div {
    top: 0px !important;
  }

  .wheel-container {
    min-height: 400px !important;
    height: 100%;
  }

  footer {
    padding-bottom: 60px !important;
  }

  .image-big {
    height: 74px !important;
  }
}

@media (max-width: 767px) {


  .Choose-homescreen button {
    font-size: 10.593px !important;
  }

  .image-big {
    height: 74px !important;
  }

  /* .parent.parent-div{
        transform: scale(.4);
      } */
  #container .css-5ctrnn:nth-child(1) {
        transform: scale(.7) !important;
      }
      #container .css-5ctrnn .div2 {
        transform: scale(.7) !important;
      }
      #container .css-5ctrnn .div3 {
        transform: scale(.7) !important;
      }

  .small-screen .makeStyles-boxShadow-2{
        width: fit-content;
        height: fit-content;
      }
      .small-screen .makeStyles-boxShadow-2 .makeStyles-boxShadow-2{
        width: 30px !important;
        height: 30px !important;
      }
      .small-screen .makeStyles-boxShadow-2 .makeStyles-boxShadow-2 div{
        font-size: 22px !important;
      }
}

.img-topcontainer-div1 {
  background-image: url(../../../../src/assets/lottery.png) !important;
  /* background: no-repeat !important; */
  background-size: 100% !important;
  display: flex !important;
  justify-content: space-between;
  flex-direction: column;
}

.top-container-card-bg {
  background: rgba(5, 5, 24, 0.8);
  border-radius: 20px;
  padding: 22px;
  margin-top: auto;
}

.img-topcontainer-div1 .MuiPaper-elevation1 {
  box-shadow: none !important;
}

.Choose-homescreen {
  width: fit-content;
  display: table;
  margin: 0 auto;
}

.Choose-homescreen button {
  border-radius: 40.879px !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 13.593px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin: 5px !important;
  text-transform: capitalize !important;
}

.image-big {
  height: 174px;
}

.Choose-homescreen button.active {
  background: #3c37ff !important;
  box-shadow: 7.299877643585205px 7.299877643585205px 14.59975528717041px
    7.299877643585205px rgba(0, 0, 0, 0.02);
}

.Choose-homescreen img {
  width: 9px;
  height: 9px;
  background: white;
  border-radius: 100%;
  padding: 7px;
  margin: 5px;
  transform: rotate(-90deg);
}

.swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background: transparent !important;
}

.circular-progress {
  position: relative;
  width: 60px;
  height: 60px;
}

.circular-progress1 {
  position: relative;
  width: 40px;
  height: 40px;
}

.buy-now-pop-up .swal2-html-container {
  display: flex !important;
  justify-content: center !important;
  height: 100px;
}

.circle {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid transparent;
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.mask {
  position: absolute;
  width: 50%;
  height: 100%;
  overflow: hidden;
  top: 0;
}

.full {
  left: 0;
  transform: rotate(0deg);
  transform-origin: 100%;
}

.half {
  left: 50%;
  transform: rotate(180deg);
  transform-origin: 0;
}

.fill {
  width: 200%;
  height: 100%;
  transform: rotate(0deg);
  transform-origin: 0;
  animation: fill-unfill 2s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fill-unfill {
  0%,
  100% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }
}

.buy-now-pop-up .swal2-title {
  font-size: 14px !important;
}

.backgroundsize100per {
  background-size: 100% 100% !important;
}

.QuickDraws-CardMedia {
  height: 250px !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.flex-column {
  flex-direction: column !important;
}

.text-center {
  text-align: center !important;
}

.h-inherit {
  height: inherit;
}
.lotterynumber-div{
  position: relative;
}
.lotterynumber-div .bollBoxContainer{
    display: "flex";
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    gap: 15px;
}
.lotterynumber-div .bollBoxContainer .bollBoxOuter{
  border-radius:12px;
  display:flex;
  margin-bottom:20px;
  border: 4px solid #3b97bb;
  width: 100% !important;
  border-radius: 8px;
  overflow: hidden;
}
 
.lotterynumber-div .bollBoxContainer .bollBoxOuter .bollBox {
  width: 18.5%;
  height: 70px; 
  display:inline-flex;                      
  justify-content:center;
  align-items:center;
  box-sizing: border-box; 
}
.lotterynumber-div .bollBoxContainer .bollBoxOuter .bollBox:last-child{
  border-right: 0px;
}
.mw-max {
  min-width: max-content !important;
} 
@media screen and (max-width: 1100px) {
  .lotterynumber-div .bollBoxContainer .bollBoxOuter .bollBox{
    height: 40px; 
  } 
}
@media screen and (max-width: 992px) {
  .lotterynumber-div .bollBoxContainer .bollBoxOuter .bollBox{
    height: 30px; 
  } 
}
@media (min-width: 1320px) {
  img#RecentActivity {
    width: 50% !important;
    height: 380px !important;
    object-fit: fill;
  }
}
@media (min-width: 0px) {
  .cstm1-crousel div:nth-child(4) {
    bottom: 0px;
    position: absolute;
  }
 
}

.wallet-table .mantine-1bi7v2x {
  position: absolute;
  left: 0;
  margin-top: 0px !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.buy-box {
  width: 100%;
  min-height: 660px;
  /* max-width: 500px; */
  color: #131b1b;
  position: relative;
  border-radius: 32px;
  /* background: #ffffff; */
  box-shadow: 50px -164px 40px #785a5414, -80px 250px 32px #785a540a;
  flex-direction: column;
  display: flex;
  margin: auto;
  border: 1px solid #29aae0;
}
.buy-box h1 {
  font-size: 20px;
  text-transform: uppercase;
  color: #ffffff;
  margin-block: 16px;
}
.walletBox_top {
  border-radius: 32px 32px 0 0;
  /* background: radial-gradient(432.9% 351.68% at 50% 50%,#162829 0%,#131b1b 100%); */
  /* border-top: 1px solid #29aae0;
    border-left: 1px solid #29aae0;
    border-right: 1px solid #29aae0; */
  height: 50%;
  flex-grow: 1;
  padding: 1rem;
}
.walletBox_top .timer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 20px;
  color: white;
  gap: 0.5rem;
}
.walletBox_top .time-card {
  min-width: 60px;
  max-width: 80px;
  height: 63px;
  width: 20%;
  padding: 8px 5px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #3c37ff;
  border-radius: 0.5rem;
}

.walletBox_top .time-card .value {
  font-size: 26px;
  line-height: 30px;
  font-weight: 700;
}
.walletBox_top .time-card .indicator {
  min-width: 60px;
  max-width: 80px;
  width: 20%;
  padding: 8px 5px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #3c37ff;
  font-size: 12px;
  font-weight: 600;
}

.walletBox_top .progress {
  width: 90%;
  border-radius: 99px;
  height: 35px;
  background: #2c3b3c;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
.walletBox_top .progress .bar {
  background: #8ac43f;
  position: absolute;
  left: 0;
  height: inherit;
}

.walletBox_top .progress .status {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 33px;
  color: #fff;
  z-index: 1;
}

.walletBox_top p {
  font-size: 16px;
  font-weight: 600;
}

.swapArea {
  position: relative;
  padding: 20px 1rem;
  width: 95%;
  margin: auto;
  flex-grow: 1;
}
.swapArea .dashTitle {
  position: relative;
  letter-spacing: 1.5px;
  color: #131b1b;
  font-size: 14px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 1rem;
}

.swapArea .dashTitle:before {
  content: "";
  width: 20%;
  height: 1px;
  background: #ffd400;
  position: absolute;
  left: 0;
  top: 50%;
}

.swapArea .dashTitle:after {
  content: "";
  width: 20%;
  height: 1px;
  background: #ffd400;
  position: absolute;
  right: 0;
  top: 50%;
}

/* 
.swapArea button {
  width: 50%;
  height: 42px;
  padding: 6px;
  border-radius: 0;
  border: 1px solid rgba(48,61,89,.5);
  color: #727b83;
  background: #ffffff;
} */
.button-div {
  display: flex;
  gap: 0.5rem;
}
.button-div button svg {
  height: 22px;
  width: 22px;
  margin-right: 8px;
}

.swapArea button.btn-connect {
  line-height: 1;
  transition: all 0.25s ease-in-out;
  font-weight: 700;
  font-size: 0.875rem !important;
  width: 100%;
  background: #29a9e0;
  color: #000000;
  margin-bottom: 0.5rem;
  border-radius: 40px;
  border: none;
}
.swapArea button.btn-buy {
  line-height: 1;
  transition: all 0.25s ease-in-out;
  font-weight: 700;
  font-size: 0.875rem !important;
  width: 100%;
  background: #9ccd5c;
  color: #000000;
  margin-bottom: 0.5rem;
  border-radius: 40px;
  border: none;
}
.swapSection {
  display: flex;
  gap: 10px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.swapSection .w-50 {
  width: 50%;
}
.swapSection input {
  padding: 8px 15px;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0;
  background-color: #f1f4f6;
  border: 1px solid #f1f4f6;
  color: #131b1b;
}
.swapSection .input-icon {
  position: absolute;
  right: calc(2% + 28px);
  top: calc(50% - 14px);
  height: 28px;
  width: 28px;
}

.goog-te-gadget-simple span {
  display: none;
}

.goog-te-gadget-simple img {
  display: none;
}

.goog-te-gadget-simple {
  height: 20px !important;
  width: 30px !important;
  background: url(../../../assets/language.png) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border: none !important;
}

.goog-te-gadget-simple:hover {
  background-color: black !important;
}

.goog-te-gadget-simple::after {
  content: "Select Language" !important;
  position: absolute !important;
  top: 100% !important;
  left: 0 !important;
  width: 100% !important;
  text-align:end !important;
  opacity: 0 !important;
  transition: opacity 0.3s, top 0.3s !important;
  color: white !important;
}

.goog-te-gadget-simple:hover::after {
  opacity: 1 !important;
  top: calc(100% + 10px) !important;
}




/* mobile portrate view */

@media screen and (max-width:600px) {
    /* mobile changes for screen rotation */

    /* .rotateScreen{  
      position: relative;
      height: 100vh;
      width: 100vw;
      overflow: hidden; 
    }
    
    
    .rotateScreen .blockArea {
      transform: rotate(90deg);
      position: fixed !important;
      top: 0px !important;
      right: 0px !important;
      bottom: 0;
      height: 100vh !important;
      width: 100vh !important;  
      overflow: auto; 
      background: #29a9e0;
  
    } */
  
    /* end */

    .walletBox_top .time-card {
      min-width: 40px;
      max-width: 60px;
      height: fit-content;
      width: fit-content;
    }
    .walletBox_top .time-card .indicator {
      min-width: fit-content;
      max-width: 80px;
      width: 100%;
    }

    .Choose-homescreen button{
      font-size: 14px !important;
      margin: 5px !important;
      padding: 5px !important;
      min-width: fit-content !important;
    }
    .Choose-homescreen img{
      padding: 5px;
      margin: 3px;
    }
}
.cstm-crousel.loginSignUp{
  min-height: 200px;
  height: auto !important;
}
.cstm-crousel.loginSignUp div:nth-child(4){
  position: relative !important;
}