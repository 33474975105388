.carousel-wrapper {
    width: 1000px;
    margin: auto;
    position: relative;
    text-align: center;
    font-family: sans-serif;
  }
  .owl-carousel .owl-nav {
    overflow: hidden;
    height: 0px;
  }
  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background: #5110e9;
  }

  .owl-carousel .owl-theme {
    flex-direction: column-reverse;
    display: flex;
  }
  
  .owl-theme .owl-nav [class*=owl-]:hover {
    background: transparent !important;
    color: #fff;
    text-decoration: none;
}
  .owl-carousel .item {
    text-align: center;
  }
  .owl-carousel button {
    height: 50px;
    width: 25px;
    cursor: pointer;
    position: absolute;
    top: 0 !important;
  }

  .owl-carousel .owl-stage {
    position: relative;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    margin-top: 35px;
    margin-left: 22px;
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation:landscape){
  .owl-carousel .owl-stage {
    margin-top: 3px;
    margin-left: 3px;
}

.owl-carousel .owl-stage-outer {
  padding: 5px;
}
}

@media only screen and (max-width: 600px) {
  .owl-carousel .owl-stage {
    margin-left: 0px;
}
.owl-stage-outer {
  padding: 15px !important;
  }
}

.owl-theme .owl-nav [class*='owl-'] {
  margin: 34px !important;
}

  .owl-stage-outer {
    padding: 25px 0px;
    background: rgba(5, 5, 24, 0.8);
    border-radius: 20px;
    padding-bottom: 30px !important;
  }
  .owl-carousel .owl-prev.disabled,
  .owl-carousel .owl-next.disabled {
    pointer-events: none;
    opacity: 0.25;
  }
  .owl-carousel .owl-prev {
    right: 50px;
  }
  .owl-carousel .owl-next {
    right: 0px;
  }
  .owl-theme .owl-nav [class*=owl-] {
    color: #ffffff;
    font-size: 39px;
    background: #000000;
    border-radius: 3px;
  }
  .owl-carousel .prev-carousel:hover {
    background-position: 0px -53px;
  }
  .owl-carousel .next-carousel:hover {
    background-position: -24px -53px;
  }