 .faq-row {
     border-bottom: none !important;
 }

 .row-title.closed {
     font-Weight: 500;
     font-style: normal;
     font-Size: 18px;
     color: rgba(255, 255, 255, 0.7);
     opacity: 0.7;
 }

 .row-title.expanded {
     font-Weight: 500;
     font-style: normal;
     font-Size: 18px;
     color: rgba(255, 255, 255, 0.8) !important;
 }

 .row-title.expanded .row-title-text::before {
     content: url(../../../assets/expanded.svg);
     margin-right: 8px;
     margin-bottom: 8px;
 }

 .row-title.closed .row-title-text::before {
     content: url(../../../assets/closed.png);
     margin-right: 8px;
     margin-bottom: 8px;
 }

 .row-content-text {
     padding-left: 15px !important;
 }