.animation { 
  position: absolute;
  right: 0;
  bottom: 0;
  width: 300px;
  height: 300px;
  /* z-index: -1; */
} 
  .animation video {
    width: 100% !important;
    height: 100% !important;
    
  }
  .background-video {
    mix-blend-mode: screen !important;
    object-fit: contain;
    height: 100%;
    width: 100%; 
    top: 0;
    left: 0;
    background-color: transparent !important;
    background: transparent;
    z-index: -1;
    mask-image: radial-gradient(circle, white, black);
    -webkit-mask-image: radial-gradient(circle, white, black);
    transform: translateX(50%);
  }
  
  @media screen and (max-width:767px) {
    .animation {  
      width: 210px;
    }
  }
  @media screen and (max-width:676px) {
    .animation {  
      width: 270px;
    }
  }