.table-t1{
    width: 100%;
    border-collapse: collapse;
    border: 1px solid white;
    border-radius: 8px;
    color: white;
    overflow-x:auto;
    background-color: black;
    margin: 5px 0px 10px 0px;
  }
  /* .table-t1 .table-th{
    text-align: center;
  } */

  .table-t1 .table-th th{
    text-align: center;
    position: sticky;
    top:0px;
    padding: 8px 20px;
    text-wrap: nowrap
  }
  .table-t1 .table-th2{
    text-align: center;
    
  }

  .table-t1 .table-th2 td{
    text-align: center;
    padding: 0px 10px;
    border-collapse: collapse;
    border: 0.5px solid white;

  }
  
  .claim-btn{
    background-color: blue;
    color: white;
    border: 1px solid white;
    border-radius: 8px;
    padding: 8px 30px;
    font-size: 15px;
    font-family: inherit;
    font-weight: bold;
    cursor: pointer;
    margin: 4px 0px 4px 0px;
    width: 100%
  }
  .redeem-btn{
    background-color: blue;
    color: white;
    border: 1px solid white;
    border-radius: 8px;
    padding: 8px 22px;
    font-size: 15px;
    font-family: inherit;
    font-weight: bold;
    cursor: pointer;
    margin: 4px 0px 4px 0px;
    width: 100%
  }
  .claim-btn:hover{
    background-color: cornflowerblue;
  }
  .redeem-btn:hover{
    background-color: cornflowerblue;
  }
  .redeem-btn-disabled{
    background-color: gray;
    color: white;
    border: 1px solid white;
    border-radius: 8px;
    padding: 8px 22px;
    font-size: 15px;
    font-family: inherit;
    font-weight: bold;
    cursor: pointer;
    margin: 4px 0px 4px 0px;
    width: 100%
  }
  .claim-btn-disabled{
    background-color: gray;
    color: white;
    border: 1px solid white;
    border-radius: 8px;
    padding: 8px 30px;
    font-size: 15px;
    font-family: inherit;
    font-weight: bold;
    cursor: pointer;
    margin: 4px 0px 4px 0px;
    width: 100%
  }

  .claim-btn-approve-disabled{
    background-color:green;
    color: white;
    border: 1px solid white;
    border-radius: 8px;
    padding: 8px 30px;
    font-size: 15px;
    font-family: inherit;
    font-weight: bold;
    cursor: pointer;
    margin: 4px 0px 4px 0px;
    width: 100%
  }

  .claim-btn-process-disabled{
    background-color:gray;
    color: white;
    border: 1px solid white;
    border-radius: 8px;
    padding: 8px 30px;
    font-size: 15px;
    font-family: inherit;
    font-weight: bold;
    cursor: pointer;
    margin: 4px 0px 4px 0px;
    width: 100%
  }

  .claim-reject-btn{
    background-color:red;
    color: white;
    border: 1px solid white;
    border-radius: 8px;
    padding: 8px 30px;
    font-size: 15px;
    font-family: inherit;
    font-weight: bold;
    cursor: pointer;
    margin: 4px 0px 4px 0px;
    width: 100%
  }