@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Ubuntu", sans-serif;
    font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #27272F !important;
  

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKcg72j00.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKew72j00.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKcw72j00.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKfA72j00.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* latin-ext */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKcQ72j00.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKfw72.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

::-webkit-scrollbar-thumb {
  border-radius: 15px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: rgb(60, 55, 255);
  border-radius: 20px;

}

::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
  border-radius: 20px;

}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.css-1e7vw6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1e7vw6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1e7vw6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 0px !important;
}

.css-mdkblq-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 16.5px 14px !important;
}
.css-183f44v-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
  border-color:rgb(167 167 167 / 87%) !important;
}

@media(min-width:1px) {
  h6.MuiTypography-root.youwin-popup {
    transform: rotate(-15deg); 
    font-weight: 900 !important;
    color: #d9a864 !important;
    z-index: 9999;
    text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
      1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
    position: absolute;
    left: 0;
    top: -40px;
  }

  .youwin-popup.top-0 {
    top: 0 !important;
    position: relative !important;
    transform: rotate(-10deg) !important;
    margin-bottom: 30px;
    text-shadow: 3px 0px 1px #fff, -1px -1px 1px #fff, 1px 1px 1px #fff !important;
    letter-spacing: 2px;
    font-size: 40px !important;
  }

  h6.MuiTypography-root.youwin-plusone {
    font-size: 30px !important;
    transform: rotate(-5deg);
    text-align: center;
    font-weight: 900 !important;
    color: #fff !important;
    z-index: 9999;
    margin-top: -25px;
    text-shadow: 2px 0 #d9a864, -2px 0 #d9a864, 0 2px #d9a864, 0 -2px #d9a864,
      1px 1px #d9a864, -1px -1px #d9a864, 1px -1px #d9a864, -1px 1px #d9a864;
  }

  .youwin-plusone.free-tikcet {
    transform: rotate(0deg) !important;
  }
}

.alltimepopup {
  width: 1px;
  height: 1px;
  top: 50% !important;
  left: 50.5% !important;
}

.alltimepopup .wheel-cstm {
  border-radius: 20px;
  width: 300px;
  transform: translate(-50%, -50%);
  margin: 0;
}

.goog-te-gadget-simple span{
 display: none;
}

.goog-te-gadget-simple img{
  display: none;
 }

 .goog-te-gadget-simple{
  height:20px !important;
  width:30px !important;
  background:url(../src/assets/language.png) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border: none !important;
 }

 .goog-te-gadget-simple:hover {
  background-color: black !important;
}

.goog-te-gadget-simple::after {
  content: "Select Language" !important;
  position: absolute !important;
  top: 100% !important;
  left: 0 !important;
  width: 100% !important;
  text-align:end !important;
  opacity: 0 !important;
  transition: opacity 0.3s, top 0.3s !important;
  color: white !important;
}

.goog-te-gadget-simple:hover::after {
  opacity: 1 !important;
  top: calc(100% + 10px) !important;
}


/* #basic-menu-1 .MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  top: 80px !important;
  left: 575px !important;
} */

iframe.skiptranslate {
  overflow-x: auto;
  width: 100% !important; 
  top:0;
  left:0;
}
.nav-contro.owl-carousel button{
  height: 40px; 
}
.nav-contro .owl-nav [class*='owl-'] {
  margin: 10px 30px !important;
}
.nav-control.owl-theme .owl-nav [class*='owl-'] {
  height: 25px !important;
  width: 25px !important;
  bottom: 0px !important;
  margin: 15px !important;
}
@media screen and (max-width: 767px) {
  .nav-control.owl-theme .owl-nav{
    display: flex;
    justify-content: center;
    overflow: visible !important;
  }
  .nav-control.owl-theme .owl-nav [class*='owl-']{
    height: 20px !important;
    width: 20px !important; 
    bottom: 0px !important;
    margin: 10px !important;
  }
  .nav-control .owl-stage-outer{
    padding:40px 15px 20px !important;
  }
}
 
.alltimepopup{
  width: 100%;
  height: 100%;
  left: 0 !important;
  top: 0 !important;
  transform: translate(0px, 0px) !important;
  overflow-y: auto; 
  background: rgb(77 77 77 / 50%) !important;
}
/* Landscape screens with a width greater than 768px */
@media (orientation: landscape) and (max-width: 1024px) {
  
  .alltimepopup .wheel-cstm { 
    transform: translate(-50%, calc(-50%));  
  }
}
